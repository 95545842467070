@import '../../../../../styles/basics';

.knowledge-hub-search-wrapper {
  @include media-breakpoint-up(md) {
    position: absolute !important;
    width: 100%;
    display: flex;
    top: 0;
    left: 0;
  }
}

.close-button {
  display: none;
  @include media-breakpoint-up(md) {
    background-image: url('/static/icons/close-black.svg');
    background-size: 28px;
    background-position: center;
    background-repeat: no-repeat;
    width: 56px;
    height: 56px;
    display: inline-block;
    margin-left: 18px;
    cursor: pointer;
  }
}

.mobile {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.desktop {
  display: none;
  @include media-breakpoint-up(md) {
    display: block;
  }
}

