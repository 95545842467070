@import '../../../styles/basics';

.block-footer {
  text-align: center;

  &__action-prefix {
    margin-bottom: 16px;
    color: $gray-1200;
  }

  &__action-suffix {
    display: flex;
    justify-content: center;
    gap: 8px;
  }

  &--white {
    .block-footer {
      &__action-prefix {
        color: $white;
      }
    }
  }
}
