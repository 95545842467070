@import '../../../../styles/basics';

.fake-search-field-wrapper {
  position: relative;

  .fake-search-field {
    width: auto;
    height: 50px;
    background-color: $gray-100;
    border-radius: 4px;
    color: $slate-1000;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 45px;
    cursor: pointer;

    &:before {
      content: '';
      background-image: url('/static/icons/knowledge-hub-search.svg');
      background-size: contain;
      position: absolute;
      width: 24px;
      height: 24px;
      left: 13px;
      top: 13px;
      display: inline-block;
    }
  }

  .clear-value-mobile {
    display: inline-block;
    color: $turquoise-1200;
    position: absolute;
    right: 0;
    top: 0;
    padding: 14px;
    font-size: 14px;
    z-index: 1;
  }

  @include media-breakpoint-up(md) {
    .fake-search-field {
      width: 270px;
    }

    .clear-value-mobile {
      display: none;
    }
  }
}
