@import '../../../../../styles/basics';

.result-stats {
  all: revert;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: $black;

  &.result-stats-desktop {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &.result-stats-mobile {
    display: block;
    text-align: center;
    padding: 0 38px 24px;
    margin-top: -10px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

}
