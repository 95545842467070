@import '../../../../../styles/basics';

.selected-filters {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
  margin-bottom: 33px;

  @include media-breakpoint-up(md) {
    border-bottom: solid 1px $slate-200;

    &.hub-home-page {
      display: none;
    }
  }

  .selected-filters-icon {
    flex-basis: 100%;
    border: solid 1px $slate-200;
    border-radius: 4px;
    padding: 12px 9px 12px 40px;
    margin-bottom: 5px;
    cursor: pointer;

    &:before {
      content: '';
      background-image: url('/static/icons/filters-icon.svg');
      background-size: contain;
      position: absolute;
      left: 9px;
      width: 25px;
      height: 25px;
      display: inline-block;

      @include media-breakpoint-up(md) {
        left: 0;
      }
    }

    &:after {
      content: '';
      background-image: url('/static/icons/arrow-right-thick.svg');
      background-size: contain;
      position: absolute;
      top: 22px;
      right: 14px;
      width: 9px;
      height: 14px;
      display: inline-block;
      @include media-breakpoint-up(md) {
        content: none;
      }
    }

    @include media-breakpoint-up(md) {
      border: none;
      flex-basis: auto;
      padding: 12px 0 12px 30px;
      margin-right: 16px;
      margin-bottom: 0;
      cursor: auto;
    }
  }

  .selected-filter {
    position: relative;
    padding: 7px 40px 7px 12px;
    background-color: $turquoise-1200;
    border-radius: 4px;
    color: $white;
    margin: 10px 10px 10px 0;

    @include media-breakpoint-up(md) {
      margin: 5px 10px 5px 0;
    }

    .remove-filter {
      display: inline-block;
      position: absolute;
      background-image: url('/static/icons/close.svg');
      background-size: contain;
      width: 20px;
      height: 20px;
      top: 9px;
      right: 12px;
    }
  }

  .clear-all-filters {
    color: $turquoise-1200;
    padding: 12px 0;
    text-decoration: underline
  }
}
