@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@value bb-hero as componentBBHero from '../index.module.scss';

.bb-hero-with-donation-widget {
  &.componentBBHero {
    background-color: transparent;
    margin-bottom: $bb-mobile-standalone-margin-new;
    padding-bottom: 0;
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }

  &:global(.behaviour-settings__top-spacing--no_spacing) {
    margin-top: 0;
  }

  &:global(.behaviour-settings__bottom-spacing--no_spacing) {
    margin-bottom: 0;
  }

  :global(.image-box) {
    // Handling image / video AR
    background: transparent;
    padding-bottom: 129%; // AR of 360x464

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, $gray-1200 23.72%, rgba(33, 36, 49, 0.77) 50.85%, rgba(33, 36, 49, 0) 100%);
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }

  :global {
    @include hero-dec();

    .red-strip {
      mix-blend-mode: unset;
      width: 135px;
      height: 50px;
      top: 24px;
      z-index: 1;
      background-image: linear-gradient(to bottom, $red-800, $red-800);
    }

    .watermark {
      width: 119px;
      height: 34px;
      right: 8px;
      top: 32px;
      z-index: 2;
    }
  }

  &--no-image {
    position: relative;
    aspect-ratio: 360/464;

    &:global(.bb) {
      background-color: $black;
      background-image: url('/static/icons/hero-social-ad-emergency-bg-shape.svg');
      background-repeat: no-repeat;
      background-size: 492px 526px;
      background-position: -178px -168px;
    }
  }

  &__container-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 2;
  }

  &__content {
    margin: 32px 0;
    color: $white;
    display: flex;
    flex-direction: column;
  }

  &__group-text {
    max-width: 480px;
  }

  &__label {
    display: inline-block;
    background: $yellow-800;
    color: $gray-1200;
    padding: 4px 8px;
    text-transform: uppercase;
    font-size: 14px;
    line-height: $line-height-large;
    font-weight: $font-weight-bold;
    margin-bottom: 16px;
  }

  &__title {
    color: $white;
    font-size: 22px;
    line-height: $line-height-large;
    font-weight: $font-weight-regular;
  }

  &__subtitle {
    margin-top: 8px;
    margin-bottom: 0;
    color: $white;
    font-size: 16px;
  }

  &__actions {
    display: flex;
    gap: 24px;
    align-items: center;
    margin-top: 24px;

    :global(.link-chevron) {
      padding: 8px 0;
      color: $white;
      text-decoration: underline;

      svg {
        width: 10px;
        height: 13px;
        margin-left: 8px;

        path {
          stroke: currentColor;
        }
      }
    }

    [data-component^="button"] {
      margin: 0;
    }
  }

  // The class is used in test suite.
  &--default {
    min-height: 0;
  }

  &--emergency {
    .bb-hero-with-donation-widget__group-text {
      max-width: 580px;
    }
  }

  // In this particular case using "down" breakpoints allows
  // to avoid double overriding for container on 992+
  @include media-breakpoint-down(lg) {
    :global(.container) {
      max-width: none;
      padding-left: 36px;
      padding-right: 36px;
    }
  }

  @include media-breakpoint-down(md) {
    :global(.container) {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  @include media-breakpoint-down(sm) {
    :global(.container) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @include media-breakpoint-up(sm) {
    :global(.image-box) {
      padding-bottom: 60%; // AR of 768x464
    }

    &--no-image {
      aspect-ratio: 768/464;

      &:global(.bb) {
        background-position: -160px -280px;
      }
    }


    &__label {
      font-size: 16px;
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      margin: 40px 0;
    }

    &__title {
      font-size: 36px;
      line-height: $line-height-normal;
    }

    &--no-image {
      &:global(.bb) {
        background-position: -160px -168px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    :global(.image-box) {
      padding-bottom: 47%; // AR of 992x464
    }

    :global(.red-strip) {
      width: 164px;
      height: 90px;
      top: 24px;
    }

    :global(.watermark) {
      width: 164px;
      height: 90px;
      right: 0;
      top: 25px;
    }

    &--no-image {
      aspect-ratio: 992/464;

      &:global(.bb) {
        background-position: -110px -168px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &:global(.bb) {
      margin-bottom: $bb-desktop-standalone-margin-new;
    }

    &:global(.behaviour-settings__top-spacing--no_spacing) {
      margin-top: 0;
    }

    &:global(.behaviour-settings__bottom-spacing--no_spacing) {
      margin-bottom: 0;
    }

    :global(.image-box) {
      &:after {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.64) 25%, rgba(0, 0, 0, 0.27) 60%);
      }
    }

    &--dec {
      .bb-hero-with-donation-widget__content {
        padding-top: 56px;
      }

      &:not(.bb-hero-with-donation-widget--emergency) {
        :global(.image-box) {
          padding-bottom: 52%;
        }

        .bb-hero-with-donation-widget__content {
          padding-top: 106px;
        }
      }
    }

    &__container-wrapper {
      align-items: stretch;
    }

    :global(.container) {
      display: flex;
      align-items: stretch;
    }

    &__content {
      margin: 40px 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    &__group-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__title {
      font-size: 48px;
      line-height: $line-height-large;
    }

    &__subtitle {
      font-size: 22px;
      margin-top: 16px;
    }

    &__widget {
      width: 392px;
    }

    &__donation-type {
      margin-bottom: 8px;
    }

    &__monthly-text {
      font-size: 12px;
      font-weight: $font-weight-bold;
      margin-bottom: 8px;
      display: flex;
      justify-content: flex-end;
      gap: 6px;

      svg {
        display: block;
        fill: currentColor;
      }
    }

    &__widget-main {
      background: $gray-100;
      border-radius: 16px;
      overflow: hidden;
    }

    &__widget-title {
      background: $gray-50;
      padding: 16px;
      font-weight: $font-weight-bold;
      color: $biscuit-1200;
      border-bottom: 1px solid $slate-200;
      text-align: center;
    }

    &__monthly-info {
      padding: 24px 24px 0;
      text-align: center;
      color: $black;
      font-size: 14px;
      line-height: $line-height-large;
    }


    &__form {
      padding: 24px;
    }

    &__submit {
      &:global([data-component^="button"]) {
        margin: 16px 0 0;
        text-transform: none;
      }
    }

    &__impact-text {
      background: $turquoise-100;
      padding: 16px 24px;
      font-size: 14px;
      color: $turquoise-1000;
      text-align: center;
      border-top: 1px dotted $slate-200;
    }

    &__security-text {
      color: $white;
      font-size: 12px;
      line-height: $line-height-normal;
      margin-top: 8px;
      padding: 8px 32px;
      text-align: center;

      svg {
        display: inline-block;
        fill: currentColor;
        margin: -2px 3px 0 0;
      }
    }

    &:not(.bb-hero-with-donation-widget--no-image) {
      .bb-hero-with-donation-widget__security-text {
        background: transparentize($black, 0.6);
        border-radius: 8px;
      }
    }

    &--no-image {
      &:global(.bb) {
        background-size: 648px 692px;
        background-position: -150px -178px;
      }
    }

    &--emergency {
      .bb-hero-with-donation-widget__widget-main {
        background: $biscuit-100;
      }

      .bb-hero-with-donation-widget__widget-title {
        background: $black;
        border-width: 0;
        color: $white;
      }

      .bb-hero-with-donation-widget__impact-text {
        background: $slate-100;
        color: $black;
      }

      &.bb-hero-with-donation-widget--no-image .bb-hero-with-donation-widget__widget-title {
        border: 1px solid $slate-600;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
      }
    }
  }

  @media (min-width: 1400px) {
    :global(.image-box) {
      padding-bottom: 39%; // AR of 1440x560
    }

    &--no-image {
      aspect-ratio: 1440/560;

      &:global(.bb) {
        background-position: -160px -128px;
      }
    }

    &--dec {
      :global(.image-box) {
        padding-bottom: 45%;
      }
    }

    &--dec {
      &:not(.bb-hero-with-donation-widget--emergency) {
        .bb-hero-with-donation-widget__content {
          padding-top: 12px;
        }
      }
    }

    &__group-text {
      max-width: 680px;
    }

    &--emergency {
      .bb-hero-with-donation-widget__group-text {
        max-width: 680px;
      }
    }
  }

  @media (min-width: 1600px) {
    &--no-image {
      &:global(.bb) {
        background-size: 806px 860px;
        background-position: -120px -78px;
      }
    }
  }
}

.money-handles-custom {
  margin-bottom: 24px;

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-start;
  }

  &__button {
    width: calc(33.33% - 6.66px);
    background: $white;
    transition: background-color 0.2s, border-color 0.2s;
    color: $slate-1000;
    padding: 11px 15px;
    font-size: 16px;
    line-height: $line-height-large;
    text-align: center;
    border-radius: 4px;
    border: 1px solid $slate-200;
    margin: 0;
    cursor: pointer;

    &:not(.money-handles-custom__button--selected):hover {
      background-color: $slate-200;
    }

    &--selected {
      color: $white;
      background-color: $turquoise-1200;
      border-color: $turquoise-1200;
    }

    input {
      display: none;
    }
  }

  &__custom-amount {
    width: 100%;

    [data-component="field-template"] {
      margin: 0;
    }

    :global(.field-type-text.field-style-normal) {
      input {
        padding-top: 11px;
        padding-bottom: 11px;
      }

      &:not(:global(.active)) input {
        border-color: $slate-200;
      }
    }
  }

  &__custom-amount-icon {
    color: $slate-1000;
    line-height: $line-height-large;
    position: absolute;
    top: 12px;
    left: 20px;
  }

  &__buttons--4 {
    .money-handles-custom__custom-amount {
      width: calc(66.66% - 3.33px);
    }
  }

  &--emergency {
    .money-handles-custom__button--selected {
      background-color: $black;
      border-color: $black;
    }
  }
}
