/*
  Adding some basic properties to make classes importable from other body blocks CSS modules.
  Regular one line comments (starting from "//") in this file don't work for Storybook.
  Interpolations like "&--variant-one-planet" also don't work for Storybook.
*/
.activism-page-type {
  background: transparent;
}

.activism-page-type--variant-one-planet {
  display: block;
}

.activism-page-type--variant-project-us {
  display: block;
}
