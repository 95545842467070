@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';
@value activism-page-type, activism-page-type--variant-project-us from '../../../../../styles/page-type.module.scss';

.bb-activism-hero {
  max-width: none;
  margin-left: 0;
  margin-right: 0;
  &:global(.bb) {
    background-color: transparent;
    margin-bottom: $bb-mobile-standalone-margin-new;
    padding-bottom: 0;
  }

  :global(.image-box) {
    // Handling image / video AR
    background: transparent;
    padding-bottom: 144.27%; // AR of 420x605

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 52.46%);
      z-index: 1;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 0;
    }
  }

  &__container-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    z-index: 2;
  }

  &__content {
    margin-bottom: 58px;
    max-width: 540px;
    color: $white;
  }

  &__label {
    margin-bottom: 8px;
    font-size: 18px;
    font-weight: $font-weight-bold;
    color: $white;
    max-width: 160px;
  }

  &__title {
    color: $white;
    font-size: 32px;
    text-transform: uppercase;
    line-height: $line-height-normal;
  }

  &.bb-activism-hero--alignment-center {
    :global(.image-box) {
      &:after {
        background: radial-gradient(100% 100% at 50.83% 0%, rgba(0, 0, 0, 0.045) 25.06%, rgba(0, 0, 0, 0.3) 73.75%);
      }
    }
  }

  &.bb-activism-hero--size-thin {
    :global(.image-box) {
      &:after {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 36.11%, rgba(0, 0, 0, 0) 100%);
      }
    }
  }

  @media (min-width: 360px) {
    &__title {
      font-size: 40px;
    }
  }

  @include media-breakpoint-up(sm) {
    :global(.image-box) {
      padding-bottom: 62.5%; // AR of 768x480
    }

    &__label {
      max-width: none;
    }

    &.bb-activism-hero--alignment-center {
      .bb-activism-hero__content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.bb-activism-hero--size-thin {
      :global(.container) {
        max-width: none;
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__title {
      font-size: 64px;
      line-height: $line-height-small;
    }
  }

  @include media-breakpoint-up(lg) {
    :global(.image-box) {
      padding-bottom: 68.55%; // AR of 992x680
    }

    &__content {
      max-width: 916px;
      margin-bottom: 83px;
    }

    &__label {
      font-size: 24px;
      margin-bottom: 16px;
    }

    &__title {
      font-size: 76px;
    }

    &.bb-activism-hero--size-thin {
      :global(.container) {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &:global(.bb) {
      margin-bottom: $bb-desktop-standalone-margin-new;
    }

    :global(.image-box) {
      padding-bottom: 56.25%; // AR of 1440x810 (16:9)
    }

    &.bb-activism-hero--size-thin {
      :global(.container) {
        max-width: 1664px; // 1600 + paddings
      }

      :global(.image-box) {
        padding-bottom: 47.22%; // AR of 1440x680
      }

      .bb-activism-hero__content {
        max-width: 1040px;
      }
    }
  }

  // We need extra breakpoint to make the block content
  // fit screen size of standard Full HD screen.
  @media (min-width: 1516px) {
    :global(.image-box) {
      padding-bottom: 50%;
    }

    &__title {
      font-size: 98px;
    }

    &.bb-activism-hero--size-default {
      .bb-activism-hero__content {
        max-width: 1200px;
      }
    }

    &.bb-activism-hero--size-thin {
      :global(.image-box) {
        padding-bottom: 35.22%; // AR of 1920x680
      }
    }
  }
}

.activism-page-type {
  .bb-activism-hero {
    .bb-activism-hero__label {
      font-family: $font-family-ibm-plex-serif-activism;
      font-style: italic;
      // Default color is the same as for
      // .activism-page-type--variant-one-planet.
      color: $yellow-800;
      // Compensation for alignment of italic font style.
      margin-left: 8px;
    }

    .bb-activism-hero__title {
      font-style: italic;

      strong {
        color: $yellow-800;
      }
    }

    @include media-breakpoint-up(lg) {
      .bb-activism-hero__label {
        // Compensation for alignment of italic font style.
        margin-left: 12px;
      }
    }
  }

  &.activism-page-type--variant-project-us {
    .bb-activism-hero {
      .bb-activism-hero__label,
      .bb-activism-hero__title strong {
        color: $yellow-600;
      }
    }
  }
}
