@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';

// TODO: Move elements to separate components.
// The styles are in use in the BBDotdigitalContactForm component.

.bb-hero-social-ad {
  &__heading {
    font-size: 32px;
    font-weight: $font-weight-regular;
    color: $gray-1200;

    strong {
      font-weight: $font-weight-bold;
    }
  }

  &__label {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 8px;
    background: $yellow-800;
    margin-bottom: 24px;
    color: $gray-1200;
  }

  &__highlighted-text {
    p {
      font-size: 18px;
    }

    strong {
      text-decoration-line: underline;
      text-decoration-color: $yellow-800;
      text-decoration-style: solid;
      text-decoration-thickness: 6px;
      text-decoration-skip-ink: none;
      text-underline-offset: 2px;
    }
  }

  &__list {
    margin-bottom: 0;
    list-style: none;

    &--yellow .bb-hero-social-ad__list-item:before {
      background: url('/static/icons/bullet-yellow.svg');
    }
  }

  &__list-item {
    & + & {
      margin-top: 24px;
    }

    padding-left: 52px;
    min-height: 40px;
    position: relative;
    margin-bottom: 0;

    &:before {
      width: 32px;
      height: 32px;
      top: 8px;
      border-radius: 0;
      background: url('/static/icons/bullet-green.svg');
    }
  }

  &__list-item-title {
    font-size: 28px;
    line-height: 1.3;
  }

  &__list-item-text p {
    font-size: 18px;
  }

  &--variant-emergency {
    .bb-hero-social-ad__list .bb-hero-social-ad__list-item:before {
      background: url('/static/icons/bullet-red.svg');
    }
  }

  @include media-breakpoint-up(xl) {
    &__heading {
      font-size: 60px;
      margin-bottom: 64px;
    }

    &__label {
      font-size: 18px;
    }

    &__list-item-title {
      font-size: 36px;
    }
  }
}
