@import '../../../../../../styles/basics';

.hero-social-ad-wrapper {
  &__top-section {
    background-color: $turquoise-800;
    background-image: url('/static/icons/hero-social-ad-bg-shape.svg');
    background-position: -52px -52px;
    background-size: 399px 426px;
    background-repeat: no-repeat;
    color: $white;
    padding-top: 78px;
    position: relative;
  }

  &__site-logo {
    position: absolute;
    top: 24px;

    a {
      display: inline-block;

      // Disabling green hover color.
      &:hover path {
        fill: $white;
      }
    }
  }

  &__logo-image {
    height: 30px;
    width: auto;
  }

  &__title {
    display: block;
    color: $white;
    font-size: 32px;
  }

  &__image {
    position: relative;
    margin-top: 24px;

    &:before {
      display: block;
      content: '';
      width: 100%;
      aspect-ratio: 320/117;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &__bottom-section {
    margin-bottom: 48px;

    .hero-social-ad-wrapper__content {
      padding-top: 40px;
    }
  }

  &__image-placeholder {
    aspect-ratio: 320/90;
  }

  &__first-info {
    margin-bottom: 32px;
  }

  &__second-info {
    margin-bottom: 48px;
  }

  .bb-hero-social-ad__content {
    padding-top: 40px;
  }

  &--variant-emergency {
    .hero-social-ad-wrapper__top-section {
      background-color: $black;
      background-image: url('/static/icons/hero-social-ad-emergency-bg-shape.svg');
    }
  }

  @include media-breakpoint-up(md) {
    &__top-section {
      padding-top: 86px;
    }

    &__site-logo {
      top: 32px;
    }

    &__image:before {
      aspect-ratio: 696/152;
    }

    &__image-placeholder {
      aspect-ratio: 696/298;
    }
  }

  @include media-breakpoint-up(lg) {
    &__top-section {
      padding-top: 94px;
    }

    &__site-logo {
      top: 40px;
    }

    &__image:before {
      aspect-ratio: 852/144;
    }

    &__image-placeholder {
      aspect-ratio: 852/306;
    }
  }

  @include media-breakpoint-up(xl) {
    & {
      min-height: 543px;
    }

    &__top-section {
      padding-top: 130px;
      padding-bottom: 70px;
      background-size: 723px 772px;

      .hero-social-ad-wrapper__content {
        display: flex;
        justify-content: space-between;
        gap: 24px;
      }
    }

    &__site-logo {
      top: 56px;
    }

    &__logo-image {
      height: 50px;
    }

    &__title {
      flex-grow: 1;
      font-size: 60px;
      max-width: 628px;
    }

    &__image{
      flex: 0 0 auto;
      width: 588px;
      margin-top: 0;

      img {
        position: absolute;
        top: -74px;
        left: 0;
        width: 588px;
        height: 430px;
        object-fit: cover;
        object-position: center center;
      }

      &:before {
        aspect-ratio: 588/42;
      }
    }

    &__bottom-section {
      .hero-social-ad-wrapper__content {
        display: flex;
        justify-content: space-between;
        gap: 24px;
      }
    }

    &__image-placeholder {
      display: none;
    }

    &__first-info {
      flex-grow: 1;
    }

    &__second-info {
      margin-bottom: 0;
      flex: 0 0 auto;
      width: 588px;
    }
  }

  @media (min-width: 1400px) {
    &__top-section,
    &__bottom-section {
      .hero-social-ad-wrapper__content {
        gap: 64px;
      }
    }
  }
}
