@import '../../../../../styles/basics';
@import '../../../../../styles/mixins/hero';

.bb-hero-social-ad-base {
  :global {
    @include hero-dec();

    .red-strip {
      mix-blend-mode: unset;
      width: 135px;
      height: 50px;
      top: 24px;
      z-index: 1;
      background-image: linear-gradient(to bottom, $red-800, $red-800);
    }

    .watermark {
      width: 119px;
      height: 34px;
      right: 8px;
      top: 32px;
      z-index: 2;
    }
  }

  &__top-section {
    background-color: $turquoise-800;
    background-image: url('/static/icons/hero-social-ad-bg-shape.svg');
    background-position: -52px -52px;
    background-size: 399px 426px;
    background-repeat: no-repeat;
    color: $white;
    padding: 78px 0 22px;
    position: relative;
  }

  &--layout-with-image {
    .bb-hero-social-ad-base__top-section {
      padding-bottom: 142px;
    }
  }

  &--variant-emergency {
    .bb-hero-social-ad-base__top-section {
      background-color: $black;
      background-image: url('/static/icons/hero-social-ad-emergency-bg-shape.svg');
    }
  }

  &__site-logo {
    position: absolute;
    top: 24px;

    a {
      display: inline-block;

      // Disabling green hover color.
      &:hover path {
        fill: $white;
      }
    }
  }

  &__logo-image {
    height: 30px;
    width: auto;
  }

  &__title {
    display: block;
    color: $white;
    font-size: 32px;
  }

  &__bottom-section {
    padding-top: 32px;
    margin-bottom: 48px;
    color: $gray-1200;
  }

  &__bottom-image {
    margin-top: -150px;
    margin-bottom: 32px;
  }

  &__image {
    position: relative;
    aspect-ratio: 320/208;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__label {
    display: inline-block;
    font-weight: bold;
    text-transform: uppercase;
    padding: 4px 8px;
    background: $yellow-800;
    margin-bottom: 24px;
    color: $gray-1200;

    &--empty {
      height: 88px;
    }

    &--dec {
      margin-top: 34px;
    }
  }

  @include media-breakpoint-up(md) {
    &__top-section {
      padding-top: 86px;
    }

    &__site-logo {
      top: 32px;
    }

    &--layout-with-image {
      .bb-hero-social-ad-base__top-section {
        padding-bottom: 176px;
      }
    }

    &__bottom-image {
      margin-top: -184px;
    }
  }

  @include media-breakpoint-up(lg) {
    :global {
      .red-strip {
        width: 164px;
        height: 90px;
        top: 24px;
      }

      .watermark {
        width: 164px;
        height: 90px;
        right: 0;
        top: 25px;
      }
    }

    &__top-section {
      padding-top: 94px;
    }

    &__site-logo {
      top: 40px;
    }

    &--layout-with-image {
      .bb-hero-social-ad-base__top-section {
        padding-bottom: 168px;
      }
    }

    &__bottom-image {
      margin-top: -176px;
    }

    &__image {
      aspect-ratio: 852/448;
    }
  }

  @include media-breakpoint-up(xl) {
    &__top-section {
      padding: 130px 0 56px;
      background-size: 723px 772px;
      background-position: -101px -52px;

      .bb-hero-social-ad-base__content {
        display: flex;
        justify-content: space-between;
        gap: 40px;
      }
    }

    &__site-logo {
      top: 56px;
    }

    &__logo-image {
      height: 50px;
    }

    &__title,
    &__top-info {
      flex: 0 0 auto;
      width: calc(50% - 20px);
    }

    &__title {
      font-size: 60px;
    }

    &__top-info {
      margin-top: -82px;
      margin-bottom: -8px;
    }

    &--layout-with-image {
      .bb-hero-social-ad-base__top-section {
        padding-bottom: 60px;
      }

      .bb-hero-social-ad-base__title {
        width: calc(100% - 656px);
      }
    }

    &__bottom-section {
      padding-top: 24px;
      margin-bottom: 64px;

      .bb-hero-social-ad-base__content {
        display: flex;
        justify-content: space-between;
        gap: 64px;
        align-items: stretch;
        flex-direction: row-reverse;
      }
    }

    &__bottom-image {
      flex: 0 0 auto;
      width: 592px;
      position: relative;
      margin-bottom: 0;
    }

    &__image{
      position: absolute;
      bottom: 0;
      right: 0;
      width: 592px;
      height: 432px;
    }

    &__label {
      font-size: 18px;
    }
  }

  @media (min-width: 1400px) {
    &__top-section {
      .bb-hero-social-ad-base__content {
        gap: 64px;
      }
    }
  }
}

.statistic-list {
  margin-bottom: 0;
  list-style: none;

  &--red .statistic-list__list-item:before {
    background: url('/static/icons/bullet-red.svg');
  }

  &__list-item {
    & + & {
      margin-top: 24px;
    }

    padding-left: 52px;
    min-height: 40px;
    position: relative;
    margin-bottom: 0;
    color: inherit;

    &:before {
      width: 32px;
      height: 32px;
      top: 8px;
      border-radius: 0;
      background: url('/static/icons/bullet-green.svg');
    }
  }

  &__list-item-title {
    font-size: 28px;
    line-height: 1.3;
    color: inherit;
  }

  &__list-item-text p {
    font-size: 18px;
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(xl) {
    &__list-item-title {
      font-size: 36px;
    }
  }
}

