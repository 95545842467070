@import '../../../../styles/basics';

.radio-button-group {
  &__label {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__buttons {
    display: flex;
    gap: 1px;
    background: $turquoise-1200;
    border-radius: 4px;
  }

  &__button {
    flex: 1 1 0;
    background: $white;
    transition: background-color 0.2s, border-color 0.2s;
    color: $slate-1000;
    padding: 6px 8px;
    font-size: 16px;
    line-height: $line-height-large;
    text-align: center;
    cursor: pointer;
    margin: 0;
    display: flex;
    gap: 8px;
    justify-content: center;
    border-style: solid;
    border-color: $turquoise-1200;
    border-width: 1px 0;

    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-right-width: 1px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    svg {
      display: block;
      fill: $gray-100;
    }

    &:not(.radio-button-group__button--selected):hover {
      background-color: $gray-100;
    }

    &--selected {
      color: $white;
      background-color: $turquoise-1200;
      svg {
        fill: currentColor;
      }
    }

    input {
      display: none;
    }
  }

  &--emergency {
    .radio-button-group__buttons {
      background: $black;
      gap: 1px;
    }
    .radio-button-group__button {
      border-color: $black;

      &--selected {
        background-color: $red-800;
      }
    }
  }

  &--large {
    .radio-button-group__button {
      padding: 11px 15px;
    }
  }

  &--extra {
    .radio-button-group__buttons {
      border-radius: 0;
      background: $white;
      gap: 0;
    }

    .radio-button-group__button {
      padding: 14px 28px;

      &:first-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &.radio-button-group--emergency {
      .radio-button-group__buttons {
        background: $white;
      }

      .radio-button-group__button {
        &--selected {
          border-color: $red-800;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    &--extra {
      .radio-button-group__button {
        font-size: 18px;

        &--selected {
          font-weight: $font-weight-bold;
        }
      }
    }
  }
}
