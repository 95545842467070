@import '../../../../../styles/basics';

.bb-hero-image-logo {
  position: relative;

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px;
  }
  &__site-logo {
    a {
      display: inline-block;

      // Disabling green hover color.
      &:hover path {
        fill: $white;
      }
    }
  }

  &__logo-image {
    height: 30px;
    width: auto;
  }

  &__image {
    position: relative;
    aspect-ratio: 16/9;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(107.48deg, rgba(0, 0, 0, 0.4) 17.41%, rgba(0, 0, 0, 0) 52.36%);
    }
  }

  @include media-breakpoint-up(md) {
    &__content {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  @include media-breakpoint-up(lg) {
    &__content {
      padding-top: 52px;
      padding-left: 36px;
      padding-right: 36px;
    }

    &__logo-image {
      height: 40px;
    }
  }

  @include media-breakpoint-up(xl) {
    &__bg {
      display: flex;
      overflow: hidden;
    }

    &__image {
      height: 352px;
      flex: 0 0 auto;
    }

    &__shape {
      flex-grow: 1;
      background-color: $turquoise-800;
      position: relative;

      &:before {
        display: block;
        content: '';
        left: -32px;
        right: -120px;
        aspect-ratio: 756/806;
        position: absolute;
        background-image: url('/static/icons/hero-social-ad-bg-shape.svg');
        background-size: cover;
        top: 50%;
        transform: translateY(-46%);
      }
    }

    &__content {
      padding-top: 40px;
    }

    &__logo-image {
      height: 50px;
    }
  }

  @media (min-width: 1400px) {
    &__shape:before {
      left: -64px;
      right: -300px;
      transform: translateY(-34%);
    }
  }

  @media (min-width: 1740px) {
    &__image {
      height: 469px;
    }

    &__shape:before {
      left: -92px;
      right: -180px;
      transform: translateY(-38%);
    }
  }
}